<style lang="less">
  .scan-doc {
    *::--webkit-media-controls-play-button, *::controls-play-button {
        display: none !important;
        -webkit-appearance: none !important;
    }
    *::-webkit-media-controls-start-playback-button, *::controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none !important;
    }
    *::-webkit-media-controls-panel, *::controls-panel {
        display: none !important;
        -webkit-appearance: none !important;
    }
  }
</style>

<style lang="less" scoped>
  .scan-doc {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .cropper {
    img {
      width: 100%;
      display: block;
    }
  }
  .plane {
    overflow: hidden;
    position: relative;
  }
  video {
    opacity: 0;
    width: auto;
    height: auto;
    &.ready {
      opacity: 1;
      object-fit: contain;
      width: 100%;
    }
  }
  .video-box {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    .border {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border: 1px dashed #FFF;
      color: rgba(255, 255, 255, .5);
    }
  }
  .bottom-btn {
    margin-top: 10px;
    background: #F4628F;
    border-radius: 8px;
    font-size: 15px;
    text-align: center;
    padding: 5px 10px;
    color: #FFF;
  }
  .header {
    padding: 0 10px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    .title {
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 5px 10px;
      text-align: center;
    }
    .cancel {
      color: #969799;
    }
    .confirm {
      color: #576b95;
    }
  }
  .placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    border: 1px dashed #dedede;
    padding: 10px;
    color: #657180;
  }
  .main-start-btn {
    color: #F4628F;
    font-size: 15px;
    font-weight: 800;
  }
</style>

<template>
  <div class="scan-doc">
    <div class="header">
      <div class="cancel" @click="close">取消</div>
      <div class="title">
        {{title || '拍照扫描'}}
      </div>
      <div class="confirm" @click="confirm">确定</div>
    </div>
    <div style="padding: 10px; overflow: hidden; box-sizing: border-box;">
      <div class="plane" v-show="step !== 2" ref="plane" v-loadingx="status.loading">
        <div class="placeholder" ref="placeholder" v-show="step === 0">
          <div style="color: rgb(255, 62, 62);" v-if="errorMsg">{{errorMsg}}</div>
          <div class="main-start-btn" v-else @click="start">点击此处开始扫描</div>
        </div>
        <div v-show="step === 1" class="video-box" ref="box">
          <video x5-video-player-type="h5" autoplay muted playsinline controls="false" preload="auto" ref="video"></video>
          <div class="border" :style="borderStyle" ref="border">请将需要扫描的物件置于白框之中</div>
        </div>
      </div>
      <div v-show="step === 2" class="cropper">
        <img src="" ref="img" />
      </div>
      <div class="bottom-btn" v-if="step === 2" @click="start">重拍</div>
      <div class="bottom-btn" v-if="step === 1" @click="capture">拍照</div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

if (!HTMLCanvasElement.prototype.toBlob) {
 Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
  value: function (callback, type, quality) {
    let binStr = atob(this.toDataURL(type, quality).split(',')[1]), len = binStr.length, arr = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
     arr[i] = binStr.charCodeAt(i)
    }
    callback(new Blob([arr], { type: type || 'image/png' }));
  }
 })
}

export default {
  props: {
    aspectRatio: { type: [Number, Boolean], default: false },
    boxMargin: { type: Number, default: 20 },
    title: { type: String, default: null }
  },
  data () {
    return {
      status: {
        loading: false
      },
      errorMsg: '',
      step: 0,
      $stream: null,
      $streamTrack: null,
      $streamCapabilities: null,
      $cropper: null,
      borderPosition: {top: this.boxMargin, bottom: this.boxMargin, left: this.boxMargin, right: this.boxMargin}
    }
  },
  computed: {
    borderStyle () {
      return {
        top: this.borderPosition.top + 'px',
        bottom: this.borderPosition.bottom + 'px',
        left: this.borderPosition.left + 'px',
        right: this.borderPosition.right + 'px'
      }
    }
  },
  methods: {
    close () {
      this.step = 0
      this.$streamTrack && this.$streamTrack.stop()
      this.$cropper && this.$cropper.destroy()
      this.$cropper = this.$streamCapabilities = this.$streamTrack = this.$refs.video.srcObject = this.$stream = null
      this.$emit('close')
    },
    confirm () {
      if (this.$cropper) {
        this.$cropper.getCroppedCanvas().toBlob((blob) => {
          this.$emit('confirm', blob)
        }, 'image/png')
      } else {
        this.$emit('confirm', null)
      }
    },
    capture () {
      if (this.$stream) {
        this.$cropper && this.$cropper.destroy()
        const widthRatio = this.streamWidth / this.$refs.box.offsetWidth
        const heightRatio = this.streamHeight / this.$refs.box.offsetHeight
        console.log('---capture---')
        console.log(widthRatio, this.streamWidth, this.$refs.box.offsetWidth)
        console.log(heightRatio, this.streamHeight, this.$refs.box.offsetHeight)
        console.log(this.borderPosition)
        let canvas = document.createElement('canvas')
        let context = canvas.getContext('2d')
        canvas.width = this.streamWidth - (this.borderPosition.left + this.borderPosition.right) * widthRatio
        canvas.height = this.streamHeight - (this.borderPosition.top + this.borderPosition.bottom) * heightRatio
        console.log(canvas.width, canvas.height)
        console.log(this.borderPosition.left * widthRatio, this.borderPosition.top * heightRatio, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
        context.drawImage(this.$refs.video, this.borderPosition.left * widthRatio, this.borderPosition.top * heightRatio, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
        this.$refs.img.src = canvas.toDataURL();
        this.$cropper = new Cropper(this.$refs.img, {
            viewMode: 1,
            autoCropArea: 1,
            zoomable: false,
            zoomOnTouch: false,
            zoomOnWheel: false,
            dragMode: 'none'
        })
        this.step = 2
        this.$streamTrack.stop()
        this.$streamCapabilities = this.$streamTrack = this.$refs.video.srcObject = this.$stream = null
      }
    },
    async getDeviceInfo () { // IOS 版本微信不支持
      if (!navigator.mediaDevices.enumerateDevices) {
        throw new Error('不支持获取媒体输入设备列表')
      }

      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevice = devices.find(v => v.kind === 'videoinput')

      if (!videoDevice) {
        throw new Error('无可供扫描使用的摄像头')
      }

      if (typeof videoDevice.getCapabilities !== 'function') {
        throw new Error('不支持获取摄像头信息')
      }

      // {width height}
      return videoDevice.getCapabilities()
    },
    async start () {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error('不支持媒体输入接口')
        }

        if (this.$cropper) {
          this.$cropper.destroy()
          this.$cropper = null
        }

        this.status.loading = true
        this.$refs.video.classList.remove('ready')
        
        let expectStreamWidth = 1080

        const constraints = {
          video: {
            facingMode: 'environment',
            width: expectStreamWidth
          },
          audio: false
        }

        let expectStreamHeight = {}
        if (this.aspectRatio) {
          expectStreamHeight = expectStreamWidth * this.aspectRatio
        } else {
          expectStreamHeight = { max: window.innerWidth * 2, ideal: window.innerHeight * 0.6, min: window.innerHeight * 0.4 }
        }
        constraints.video.height = expectStreamHeight

        console.log(this.aspectRatio, constraints)
        this.$stream = await navigator.mediaDevices.getUserMedia(constraints)
        this.$streamTrack = this.$stream.getTracks()[0]
        this.$streamCapabilities = this.$streamTrack.getCapabilities()
        console.log(this.$streamCapabilities)

        if ('srcObject' in this.$refs.video) {
          this.$refs.video.srcObject = this.$stream
        } else {
          throw new Error('媒体资源无可用输出对象')
        }
        this.step = 1
        
        let timer = null
        let play = () => {
          clearTimeout(timer)
          this.$refs.video.play()
          this.status.loading = false
        }

        timer = setTimeout(play, 200)
        this.$refs.video.onloadedmetadata = play
      } catch (error) {
        this.status.loading = false
        this.errorMsg = '当前浏览器或设备不支持，请升级，错误原因: ' + error.message
      }
    },
    onVideoPlaying () {
      this.streamWidth = this.$refs.video.offsetWidth
      this.streamHeight = this.$refs.video.offsetHeight
      const aspectRatio = this.streamWidth / this.streamHeight
      const planeHeight = this.$refs.plane.offsetWidth / aspectRatio
      this.$refs.box.style.height = planeHeight + 'px'

      console.log('onVideoPlaying', planeHeight, this.aspectRatio, this.$refs.plane.offsetHeight, planeHeight)
      console.log(this.streamWidth, this.streamHeight)


      if (this.aspectRatio === false) {
        let boxHeightCorrection = 0
        if (this.$refs.plane.offsetHeight > planeHeight) {
          this.$refs.plane.style.height = planeHeight + 'px'
        } else {
          boxHeightCorrection = (planeHeight - this.$refs.plane.offsetHeight) / 2
        }
        this.borderPosition.left = this.boxMargin
        this.borderPosition.right = this.boxMargin
        this.borderPosition.top = this.boxMargin + boxHeightCorrection
        this.borderPosition.bottom = this.boxMargin + boxHeightCorrection
      } else {
        const boxMaxHeight = planeHeight - (this.boxMargin * 2)
        const boxMaxWidth = this.$refs.plane.offsetWidth - (this.boxMargin * 2)
        const boxHeight = boxMaxWidth * this.aspectRatio
        const boxAspectRatio = boxMaxWidth / boxHeight
        if (boxHeight > boxMaxHeight) {
          const boxWidthCorrection = (boxMaxWidth - boxMaxHeight * boxAspectRatio) / 2
          this.borderPosition.top = this.boxMargin
          this.borderPosition.bottom = this.boxMargin
          this.borderPosition.left = (this.boxMargin + boxWidthCorrection)
          this.borderPosition.right = (this.boxMargin + boxWidthCorrection)
        } else {
          const boxHeightCorrection = (boxMaxHeight - boxHeight) / 2
          this.borderPosition.top = (this.boxMargin + boxHeightCorrection)
          this.borderPosition.bottom = (this.boxMargin + boxHeightCorrection)
          this.borderPosition.left = this.boxMargin
          this.borderPosition.right = this.boxMargin
        }
      }
      this.$refs.video.classList.add('ready')
    }
  },
  mounted () {
    this.$refs.video.controls = false
    this.$refs.video.muted = true
    this.$refs.video.autoplay = true
    this.$refs.video.onplaying = this.onVideoPlaying
    this.$refs.plane.style.height = (this.aspectRatio ? (this.$refs.plane.offsetWidth * this.aspectRatio) : window.innerHeight * 0.6) + 'px'
  }
}
</script>
